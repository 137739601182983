import './new.css'

export default function LoadingBox(){
    return (
      //   <div class="loader">
      //   <div></div>
      //   <div></div>
      //   <div></div>
      //   <div></div>
      //   <div></div>
      // </div>
<div className="loader">
  <h1>
  <span className="let1">l</span>
  <span className="let2">o</span>
  <span className="let3">a</span>
  <span className="let4">d</span>
  <span className="let5">i</span>
  <span className="let6">n</span>
  <span className="let7">g</span>
  <span className="let8">.</span>
  <span className="let9">.</span>
  <span className="let10">.</span>
</h1>
</div>
    )
}